@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  transition: all 200ms ease-in-out;
}

#title {
  font-family: "Bellota", cursive;
}